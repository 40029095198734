@import "./colors";

.mat-card {
  padding: 0 !important;
  .card-header {
    border-bottom: 1px solid $light-gray;
    padding: 0.5rem 1rem;
  }
  .card-body {
    padding: 0.5rem 1rem;
  }
}

@import './anchors';
@import './borders';
@import './buttons';
@import './cards';
@import './colors';
@import './dropdown';
@import './errors';
@import './icons';
@import './inputs';
@import './fonts';
@import './slide';
@import './spinner';
@import './tabs';
@import './table';
@import './toast';
@import './forms';
@import './tooltips';

body {
  & * {
    font-family: 'Open Sans', sans-serif;
  }
}

table {
  width: 100%;
}

@import './colors';

.dropdown-container {
  position: relative;

  .dropdown {
    position: absolute;
    padding: 0.5rem 0;

    width: max-content;

    background-color: $header-dropdown-container;

    border-radius: 5px;

    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

    z-index: 900;

    i.fas {
      color: $black;
    }

    a {
      color: unset;
    }

    &-right {
      right: 0;
    }

    &-center {
      left: 50%;
      transform: translateX(-50%);
    }

    .dropdown-item {
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      &:hover {
        background-color: rgba($light-gray, 0.3);
        border-radius: 2px;
      }
    }
  }
}

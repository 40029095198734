@import './colors';

div.form-container {
  border: 1px solid $light-gray;
  box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.25);

  div.title {
    border-bottom: 2px solid $light-gray;
  }
}

.disabled-paypal {
  opacity: .2;
  pointer-events: none;
  touch-action: none;
}
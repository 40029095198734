.mat-cell,
.mat-header-cell {
  padding: 0 0.75rem !important;
}

.mat-header-cell {
  font-weight: 700;
  font-size: 14px;

  color: rgba(0, 0, 0, 1) !important;
}

@import './colors';

.mat-tab-label-container {
  .mat-tab-label-active {
    font-weight: 700 !important;

    background-color: $yellow !important;
    color: $white !important;

    border: none !important;

    opacity: 1 !important;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .mat-ink-bar {
    display: none !important;
    visibility: hidden !important;
  }
}

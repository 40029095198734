$toast-bg: #fff;
$toast-color: #000;
$toast-progressBar: #c7c7c7;
$toast-progressBarPercentage: #4c4c4c;

$border-width: 4px;
$simple-border-color: #000;
$success-border-color: #4caf50;
$info-border-color: #1e88e5;
$warning-border-color: #ff9800;
$error-border-color: #f44336;
$async-border-color: $info-border-color;
$confirm-border-color: #009688;
$prompt-border-color: $confirm-border-color;

$snotify-title-font-size: auto !default;
$snotify-body-font-size: auto !default;

@if $snotify-title-font-size == auto {
  $snotify-title-font-size: 1.8em;
}

@if $snotify-body-font-size == auto {
  $snotify-body-font-size: 1em;
}

.snotifyToast {
  display: block;
  cursor: pointer;
  background-color: $toast-bg;
  max-height: 300px;
  height: 100%;
  margin: 5px;
  opacity: 0;
  overflow: hidden;
  pointer-events: auto;

  &--in {
    animation-name: appear;
  }

  &--out {
    animation-name: disappear;
  }

  &__inner {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    padding: 5px 65px 5px 15px;
    min-height: 78px;
    font-size: 16px;
    color: $toast-color;
  }

  &__progressBar {
    position: relative;
    width: 100%;
    height: 5px;
    background-color: $toast-progressBar;

    &__percentage {
      position: absolute;
      top: 0;
      left: 0;
      height: 5px;
      background-color: $toast-progressBarPercentage;
      max-width: 100%;
    }
  }

  &__title {
    font-size: $snotify-title-font-size;
    line-height: 1.2em;
    margin-bottom: 5px;
    color: $toast-color;
  }

  &__body {
    font-size: $snotify-body-font-size;
    color: $toast-color;
  }
}

.snotifyToast-show {
  transform: translate(0, 0);
  opacity: 1;
}

.snotifyToast-remove {
  max-height: 0;
  overflow: hidden;
  transform: translate(0, 50%);
  opacity: 0;
}

/***************
 ** Modifiers **
 **************/

.snotify-simple {
  border-left: $border-width solid $simple-border-color;
}

.snotify-success {
  border-left: $border-width solid $success-border-color;
}

.snotify-info {
  border-left: $border-width solid $info-border-color;
}

.snotify-warning {
  border-left: $border-width solid $warning-border-color;
}

.snotify-error {
  border-left: $border-width solid $error-border-color;
}

.snotify-async {
  border-left: $border-width solid $async-border-color;
}

.snotify-confirm {
  border-left: $border-width solid $confirm-border-color;
}

.snotify-prompt {
  border-left: $border-width solid $prompt-border-color;
  ng-snotify-prompt {
    width: 100%;
  }
}

.snotify-confirm,
.snotify-prompt {
  .snotifyToast__inner {
    padding: 10px 15px;
  }
}

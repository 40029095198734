@import 'colors';

a {
  cursor: pointer;
  &.common {
    text-decoration: underline;
    color: $anchor-common;
  }

  &.action {
    display: flex;

    align-items: center;

    padding: 0.25rem 0.5rem;

    border-radius: 4px;

    &:hover {
      background-color: rgba($anchor-action-hover, 0.5);
      color: $white !important;
    }
    &:active,
    &:link,
    &:visited {
      text-decoration: none;

      color: unset;
    }
    i {
      margin-right: 0.25rem;
    }
  }
  &.btn {
    padding: 0.5rem 1rem;
    border-radius: 4px;

    &.success {
      background-color: $green;
      color: $white !important;
    }
    &.error {
      background-color: $red;
      color: $white !important;
    }
    &.warn {
      background-color: $orange;
      color: $white !important;
    }
    &:active,
    &:link,
    &:visited {
      text-decoration: none;

      color: unset;
    }
  }
  &.link {
    display: flex;

    padding: 0.25rem;

    &:active,
    &:link,
    &:visited {
      text-decoration: none;

      color: unset;
    }

    .icon {
      max-width: 24px;
      min-width: 24px;
      max-height: 24px;
      min-height: 24px;

      margin-right: 0.5rem;

      display: flex;

      align-items: center;

      justify-content: center;
    }
    .text {
      display: flex;

      align-items: center;
    }
  }
}

@import './colors';

button {
  &.btn {
    padding: 0.25rem 1.5rem;
    &.primary {
      background-color: $button-primary;
      color: $white;

      font-size: 16px;

      &.outlined {
        border: 1px solid $button-primary;
        color: $button-primary;
        background-color: $white;
      }
    }
    &.secondary {
      background-color: $button-secondary;
      color: $white;

      font-size: 16px;
    }
    &.tertiary-outlined {
      border: 1px solid $button-tertiary;
      color: $button-tertiary;

      font-size: 16px;
    }
    &.muted {
      font-size: 16px;

      padding: 0;
    }
    &.wide {
      padding: 0 1.5rem;
    }
  }
}

.mat-button-toggle-checked {
  background-color: $toggle-checked-color !important;
  color: $white !important;
}

@import "./screen-sizes";

@mixin border-radius {
  @for $i from 1 through 16 {
    .border-radius-#{$i} {
      border-radius: #{$i}px;
    }

    .border-top-radius-#{$i} {
      border-top-left-radius: #{$i}px;
      border-top-right-radius: #{$i}px;
    }

    .border-bottom-radius-#{$i} {
      border-bottom-left-radius: #{$i}px;
      border-bottom-right-radius: #{$i}px;
    }

    .border-left-radius-#{$i} {
      border-top-left-radius: #{$i}px;
      border-bottom-left-radius: #{$i}px;
    }

    .border-right-radius-#{$i} {
      border-top-right-radius: #{$i}px;
      border-bottom-right-radius: #{$i}px;
    }

    .border-top-left-radius-#{$i} {
      border-top-left-radius: #{$i}px;
    }

    .border-top-right-radius-#{$i} {
      border-top-right-radius: #{$i}px;
    }

    .border-bottom-left-radius-#{$i} {
      border-bottom-left-radius: #{$i}px;
    }

    .border-bottom-right-radius-#{$i} {
      border-bottom-right-radius: #{$i}px;
    }
  }
}

@include border-radius;

@media only screen and (max-width: $mobile-max) {
}

@media only screen and (max-width: $tablet-max) and (min-width: $tablet-min) {
}

@media only screen and (max-width: $screen-small-max) and (min-width: $screen-small-min) {
}

@media only screen and (max-width: $screen-max) and (min-width: $screen-min) {
}

@media only screen and (min-width: $screen-large-min) {
}

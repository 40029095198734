.snotifyToast__input {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 0;
  width: 100%;
  vertical-align: top;
  transition: all 0.5s;
  transition-delay: 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

  &__field {
    position: relative;
    display: block;
    float: right;
    padding: 0.85em 0.5em;
    width: 100%;
    border: none;
    border-radius: 0;
    background: transparent;
    color: #333;
    font-weight: bold;
    -webkit-appearance: none; /* for box shadows to show on iOS */
    opacity: 0;
    transition: opacity 0.3s;

    &:focus {
      outline: none;
    }
  }

  &__label {
    display: inline-block;
    float: right;
    padding: 0 0.85em;
    width: 100%;
    color: #999;
    font-weight: bold;
    font-size: 70.25%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    left: 0;
    height: 100%;
    text-align: left;
    pointer-events: none;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: transform 0.3s;
    }

    &::before {
      border-top: 2px solid $prompt-border-color;
      transform: translate3d(0, 100%, 0) translate3d(0, -2px, 0);
      transition-delay: 0.3s;
    }

    &::after {
      z-index: -1;
      background: #eee;
      transform: scale3d(1, 0, 1);
      transform-origin: 50% 0;
    }
  }

  &__labelContent {
    position: relative;
    display: block;
    padding: 1em 0;
    width: 100%;
    transition: transform 0.3s 0.3s;
  }
}

.snotifyToast__input--filled {
  margin-top: 2.5em;

  &:focus,
  .snotifyToast__input__field {
    opacity: 1;
    transition-delay: 0.3s;
  }
}

.snotifyToast__input__field:focus
  + .snotifyToast__input__label
  .snotifyToast__input__labelContent,
.snotifyToast__input--filled .snotifyToast__input__labelContent {
  transform: translate(0, -80%);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.snotifyToast__input__field:focus + .snotifyToast__input__label::before,
.snotifyToast__input--filled .snotifyToast__input__label::before {
  transition-delay: 0s;
}

.snotifyToast__input__field:focus + .snotifyToast__input__label::before,
.snotifyToast__input--filled .snotifyToast__input__label::before {
  transform: translate(0, 0);
}

.snotifyToast__input__field:focus + .snotifyToast__input__label::after,
.snotifyToast__input--filled .snotifyToast__input__label::after {
  transform: scale(1, 1);
  transition-delay: 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.snotifyToast {
  &--invalid {
    .snotifyToast__input__label::before {
      border-color: $error-border-color;
    }
  }
  &--valid {
    .snotifyToast__input__label::before {
      border-color: $success-border-color;
    }
  }
}

$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../../fonts/icomoon" !default;

$icon-credit: "\e900";
$icon-history: "\e901";
$icon-arrow-right: "\e902";
$icon-pickups: "\e903";
$icon-cancel: "\e904";
$icon-users: "\e905";
$icon-currency: "\e906";
$icon-orders: "\e907";
$icon-upload: "\e95f";
$icon-chat: "\e96b";
$icon-user: "\e971";
$icon-settings: "\e994";
$icon-eye: "\e9ce";
$icon-logout: "\ea14";
$icon-eye-closed: "\e9d1";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?48ibhb");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?48ibhb#iefix")
      format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?48ibhb")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?48ibhb")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?48ibhb##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-credit {
  &:before {
    content: $icon-credit;
  }
}
.icon-history {
  &:before {
    content: $icon-history;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-pickups {
  &:before {
    content: $icon-pickups;
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel;
  }
}
.icon-users {
  &:before {
    content: $icon-users;
  }
}
.icon-currency {
  &:before {
    content: $icon-currency;
  }
}
.icon-orders {
  &:before {
    content: $icon-orders;
  }
}
.icon-upload {
  &:before {
    content: $icon-upload;
  }
}
.icon-chat {
  &:before {
    content: $icon-chat;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
  }
}
.icon-logout {
  &:before {
    content: $icon-logout;
  }
}
.icon-eye-closed {
  &:before {
    content: $icon-eye-closed;
  }
}

@import "./screen-sizes";

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (max-width: $mobile-max) {
}

@media only screen and (max-width: $tablet-max) and (min-width: $tablet-min) {
}

@media only screen and (max-width: $screen-small-max) and (min-width: $screen-small-min) {
}

@media only screen and (max-width: $screen-max) and (min-width: $screen-min) {
}

@media only screen and (min-width: $screen-large-min) {
}

/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "admin.scss";

.d-print-only {
  display: none !important;
}

@media print {
  .d-print-only {
    display: inherit !important;
  }

  .d-print-none,
  iframe,
  .d-print-none * {
    display: none !important;
  }
}

@import "./colors";
.mat-slide-toggle {
  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: rgba($mat-toggle, 0.54);
    }
    .mat-slide-toggle-thumb {
      background-color: $mat-toggle;
    }
  }
}

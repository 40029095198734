$light-blue: #86b4eb;
$blue: #5a89c0;
$medium-blue: #4a90e2;
$dark-blue: #219bdd;
$darker-blue: #19355e;
$light-red: #f09d9d;
$red: #e02020;
$dark-red: #ba2a2a;
$lighter-green: #86e0b9;
$light-green: #76bd1d;
$green: #60a587;
$dark-green: #406816;
$darker-green: #325708;
$success-green: #b7ea80;
$white: #ffffff;
$lighter-gray: #fafafa;
$light-gray: #cacaca;
$gray: #9b9b9b;
$lighter-black: #4a4a4a;
$black: #000000;
$light-yellow: #fbc512;
$yellow: #f5be0b;
$dark-yellow: #753b14;
$orange: #ff641a;

$anchor-common: $darker-blue;
$anchor-action-hover: $darker-blue;
$background-auth: $darker-blue;
$background-menu: $darker-blue;
$button-primary: $darker-blue;
$button-secondary: $yellow;
$button-tertiary: $green;
$header-background: $yellow;
$header-button: $darker-blue;
$header-icon: $lighter-black;
$header-separator: $lighter-black;
$header-dropdown-container: $lighter-gray;
$input-background: $lighter-gray;
$loader-background-1: $gray;
$loader-background-2: $darker-blue;
$loader-chunk: $darker-blue;
$mat-toggle: $yellow;
$menu-item: $dark-blue;
$menu-item-active: $yellow;
$menu-item-hover: $light-blue;
$toggle-checked-color: $darker-blue;

$colors: (
  'light-blue': $light-blue,
  'blue': $blue,
  'dark-blue': $dark-blue,
  'darker-blue': $darker-blue,
  'light-red': $light-red,
  'red': $red,
  'dark-red': $dark-red,
  'lighter-green': $lighter-green,
  'light-green': $light-green,
  'green': $green,
  'success-green': $success-green,
  'dark-green': $dark-green,
  'darker-green': $darker-green,
  'white': $white,
  'lighter-gray': $lighter-gray,
  'gray': $gray,
  'lighter-black': $lighter-black,
  'black': $black,
  'light-yellow': $light-yellow,
  'yellow': $yellow,
  'dark-yellow': $dark-yellow,
  'orange': $orange,
);

@each $name, $code in $colors {
  .color-#{$name} {
    color: $code;
    &::before,
    &::after {
      color: $code;
    }
  }
  .bg-#{$name} {
    background-color: $code;
    &::before,
    &::after {
      background-color: $code;
    }
  }
}

.overlay {
  position: fixed;

  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  z-index: 1000;

  // TODO: These should be fed with color variables
  &.light {
    background-color: rgba(100%, 100%, 100%, 0.2);
  }

  &.dark {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
